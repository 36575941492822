import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter" style={{backgroundColor: `#def2fa`}}>
        <div
          className="content has-text-centered has-text-white-ter"
          style={{backgroundColor: `#def2fa`, padding: `20px 0`}}
        >
          <div className="container has-text-white-ter">
            <div className="columns" style={{justifyContent: `flex-end`}}>
              <div className="column is-4 social" style={{textAlign: `right`}}>
                <a
                  title="twitter"
                  href="https://twitter.com/lingerie1108"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em', marginBottom: '0' }}
                  />
                </a>
                <a
                  title="instagram"
                  href="https://www.instagram.com/chiichoro/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em', marginBottom: '0' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
