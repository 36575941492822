import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.svg";
import styled from 'styled-components'

const Navigation = styled.nav`
`
const Container = styled.div`
`
const NavBarBrand = styled.div`
  display: flex;
  align-items: center;
`

const NavBarLogo = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Dancing+Script:700&display=swap&subset=latin-ext');
  font-family: 'Dancing Script', cursive;
  font-size: 40px;
`

const NavMenu = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
`

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    return (
      <Navigation
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
        style={{backgroundColor: `#faf9f6`}}
      >
        <Container className="container" style={{ padding: `10px 0` }}>
          <NavBarBrand>
            <Link to="/" className="navbar-item" title="Logo">
              <NavBarLogo>chiicho</NavBarLogo>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </NavBarBrand>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
            style={{backgroundColor: `#faf9f6`}}
          >
            <NavMenu
              className="navbar-start has-text-centered"
              style={{justifyContent: `flex-end`, marginRight: `0px`, marginLeft: `auto`} }
            >
              <Link
                className="navbar-item"
                to="/about"
                style={{
                  fontWeight: `600`,
                  fontSize: `20px`,
                }}>
                Profile
              </Link>
              <Link
                className="navbar-item"
                to="/picture"
                style={{
                  fontWeight: `600`,
                  fontSize: `20px`,
                }}>
                Pictures
              </Link>
              <Link
                className="navbar-item"
                to="/blog"
                style={{
                  fontWeight: `600`,
                  fontSize: `20px`,
                }}>
                Blog
              </Link>
              <Link
                className="navbar-item"
                to="/contact"
                style={{
                  fontWeight: `600`,
                  fontSize: `20px`,
                  padding: `10px 0`
                }}>
                Contact
              </Link>
            </NavMenu>
          </div>
        </Container>
      </Navigation>
    );
  }
};

export default Navbar;
