import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import Testimonials from '../components/Testimonials'
import Pricing from '../components/Pricing'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
`
const SubContainer = styled.div`
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #faf9f6;
`
const Title = styled.h2`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  font-size: 28px;
  color: black;
  text-align: center;
  padding: 50px 0;
  margin: 0 auto;
  font-weight: 600;
`
const Section = styled.section`
  background-color: #faf9f6;
`
const ImageBox = styled.div`
`
const ImageBoxInner = styled.div`
`


export const PicturePageTemplate = ({
  title,
  intro
}) => (
  <Container>
    <Header>
      <Title>{title}</Title>
    </Header>
    <Section>
      <SubContainer>
        <ImageBox>
          <ImageBoxInner>
            <Features gridItems={intro.blurbs} />
          </ImageBoxInner>
        </ImageBox>
      </SubContainer>
    </Section>
  </Container>
)

PicturePageTemplate.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const PicturePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PicturePageTemplate
        title={frontmatter.title}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

PicturePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PicturePage

export const picturePageQuery = graphql`
  query PicturePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
