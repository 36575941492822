import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width:991px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
const Column = styled.div`
  width: 50%;
  max-width: 480px;
  margin-bottom: 50px;
  @media screen and (min-width:768px) and ( max-width:991px) {
    max-width: 540px;
    width: 100%;
  }
  @media screen and (max-width:767px) {
    max-width: 320px;
    width: 100%;
  }
`
const Section = styled.div`
`
const Image = styled.div`
  max-width: 480px;
  @media screen and (min-width:768px) and ( max-width:991px) {
    max-width: 540px;
  }
  @media screen and (max-width:767px) {
    max-width: 320px;
  }
`
const Text = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');
  font-family: 'Sawarabi Mincho', sans-serif;
  margin-top: 20px;
`

const FeatureGrid = ({ gridItems }) => (
  <Container>
    {gridItems.map(item => (
      <Column key={item.text}>
        <Section>
          <Image>
            <PreviewCompatibleImage imageInfo={item} />
          </Image>
          <Text>{item.text}</Text>
        </Section>
      </Column>
    ))}
  </Container>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
